<template>
    <v-dialog v-model="dialog" max-width="600" :fullscreen="$vuetify.breakpoint.xs">
        <v-card :loading="loading">
            <v-card-title>
                Dados Job
                <v-btn @click="getData(id)" icon :disabled="loading">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="handleClose" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-subtitle>
                ID: {{ id }}
            </v-card-subtitle>

            <v-card-text v-if="job">
                <v-list dense>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>
                                Status
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                <JobStatusChip
                                :id="job.status.id"
                                :name="job.status.name"
                                :type="job.status.type"
                                x-small
                            />
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="job.lead">
                        <v-list-item-content>
                            <v-list-item-title>
                                Lead
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                <v-chip
                                    small
                                    label
                                    :to="{
                                        name: 'lead_detail_data',
                                        params: {
                                            lead_number: job.lead.identifier_code
                                        }
                                    }"
                                >
                                    {{ job.lead.identifier_code }}
                                </v-chip>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>
                                Autor
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ job.creator.name }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>
                                Robô Responsável
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ job.robot ? job.robot.id : "Nenhum" }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>
                                Data de criação
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ job.created_at }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>
                                Última modificação
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ job.updated_at }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ job.action.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                AID: {{ job.action.id }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-timeline class="d-none d-sm-inline-block" dense>
                    <v-timeline-item v-for="log in job.logs" :key="log.id" fill-dot small :color="getColor(log.type)" :icon="log.is_a_sytem_action ? 'mdi-cog' : ''" :title="log.is_a_sytem_action ? 'Ação automática' : 'Ação manual'">
                        <v-row class="pt-1" dense>
                            <v-col>
                                <strong>
                                    <router-link 
                                        v-if="job.status_id == 'needs_user_input' && log.technical_details.status.id == 'needs_user_input'"
                                        class="link"
                                        :to="{
                                            name: 'lead_new_import_robot',
                                            params: {
                                                job_id: job.id,
                                            }
                                        }"
                                    >
                                        {{ log.message }}
                                    </router-link>
                                    <span v-else>
                                        {{ log.message }}
                                    </span>
                                </strong>
                                <div class="text-caption">
                                    Responsável: {{ log.author ? log.author.name : "Sistema"}}
                                </div>
                                <div class="text-caption">
                                    {{ log.created_at }}
                                </div>
                                <div>
                                    <JobStatusChip
                                        :id="log.technical_details.status.id"
                                        :name="log.technical_details.status.name"
                                        :type="log.technical_details.status.type"
                                        x-small 
                                    />
                                    <v-chip @click="logDetails(log)" x-small outlined class="ml-1">
                                        detalhes
                                    </v-chip>
                                </div>
                            </v-col>
                        </v-row>
                    </v-timeline-item>
                </v-timeline>
            </v-card-text>
            <v-list v-if="job" class="d-sm-none">
                <v-subheader>LOGS</v-subheader>
                <v-list-item v-for="log in job.logs" :key="log.id" fill-dot small :color="getColor(log.type)" :icon="log.is_a_sytem_action ? 'mdi-cog' : ''" :title="log.is_a_sytem_action ? 'Ação automática' : 'Ação manual'">
                    <v-list-item-content>
                        <v-list-item-title class="text-wrap">
                            <router-link 
                                v-if="job.status_id == 'needs_user_input' && log.technical_details.status.id == 'needs_user_input'"
                                class="link"
                                :to="{
                                    name: 'lead_new_import_robot',
                                    params: {
                                        job_id: job.id,
                                    }
                                }"
                            >
                                {{ log.message }}
                            </router-link>
                            <span v-else>
                                {{ log.message }}
                            </span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            Responsável: {{ log.author ? log.author.name : "Sistema"}}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                            {{ log.created_at }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                            <JobStatusChip
                                :id="log.technical_details.status.id"
                                :name="log.technical_details.status.name"
                                :type="log.technical_details.status.type"
                                x-small 
                            />
                            <v-chip @click="logDetails(log)" x-small outlined class="ml-1">
                                detalhes
                            </v-chip>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </v-dialog>
</template>

<script>
import { show } from "@/services/lusitania/robots/jobs";
import JobStatusChip from "@/components/lusitania/JobStatusChip";

export default {
    components: { JobStatusChip },

    data() {
        return {
            dialog: false,
            id: null,
            job: null,
            loading: false
        };
    },

    methods: {
        getColor(type) {
            switch(type) {
                case "error": return "red";
                case "warning": return "orange";
            }

            return "primary";
        },
        logDetails(log) {
            this.$store.commit('alert', 'Detalhes técnicos disponíveis no console')

            console.info({...log.technical_details});
        },
        init(id) {
            this.job = null;

            this.dialog = true;
            this.id = id;
            this.getData();
        },
        handleClose() {
            this.dialog = false;

            this.$emit("close");
        },
        async getData() {
            this.loading = true;
            try {
                const response = await show(this.id);
                this.job = response.data;
            } catch(error) {
                this.$store.commit('error', "Job não encontrado");
                this.handleClose();
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>