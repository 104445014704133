<template>
    <v-container>
        <div class="text-h4">
            {{ $capitalize($tc('model.automation_automations_title')) }}
        </div>
        <div class="text-h6 mb-10 blue--text">
            <v-icon color="blue">mdi-test-tube</v-icon> {{ $capitalize($tc('model.automation_experimental_subtitle')) }} 
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-on="on" v-bind="attrs" icon x-small>
                        <v-icon small>mdi-help-circle</v-icon>
                    </v-btn>
                </template>
                <span>
                    {{ $capitalize($tc('model.automation_informational_automation')) }} <br>
                    {{ $capitalize($tc('model.automation_informational_feedback')) }}
                </span>
            </v-tooltip>
        </div>

        <v-card>
            <v-card-title>
                {{ $capitalize($tc('model.automation_tasks_title')) }}
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field 
                            v-model="filter" 
                            @keyup.enter="getItems(false, true)" 
                            :label="$capitalize($tc('model.automation_search_filter'))" 
                            outlined dense hide-details clearable 
                            @click:clear="handleClearFilters" 
                            prepend-inner-icon="mdi-magnify">
                        </v-text-field>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn @click="handleOpen" color="primary">
                            <v-icon left>mdi-plus</v-icon> {{ $capitalize($tc('model.automation_new_action_button')) }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-tabs v-model="tab" show-arrows>
                <v-tab>
                    {{ $capitalize($tc('model.automation_open_tab')) }}
                </v-tab>
                <v-tab>
                    {{ $capitalize($tc('model.automation_closed_tab')) }}
                </v-tab>
                <v-tab>
                    {{ $capitalize($tc('model.automation_all_tab')) }}
                </v-tab>
            </v-tabs>
            <v-divider></v-divider>
            <v-toolbar dense elevation="0">
                <div class="d-none d-sm-inline">
                    <span class="grey--text">
                        {{ $capitalize($tc('model.automation_selection_checkbox')) }}: {{ selected.length }}/{{ totalItems }}
                    </span>
                    <v-btn @click="selected = []" icon x-small class="ml-1">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                    <v-divider vertical class="mx-4"></v-divider>
                </div>
                <v-btn @click="handleViewJob" icon :disabled="!canView">
                    <v-icon>mdi-eye</v-icon>
                </v-btn>
                <v-btn @click="handleRestoreItems" color="info" icon :disabled="!canRestore">
                    <v-icon>mdi-backup-restore</v-icon>
                </v-btn>
                <v-btn @click="handleCancelItems" :disabled="!canClose" color="red" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn @click="getItems" icon>
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table 
                v-model="selected" 
                show-select 
                must-sort
                :headers="headers" 
                :items="items" 
                :loading="loading" 
                :options.sync="options"
                :server-items-length="totalItems"
                :footer-props="{
                    itemsPerPageOptions: [
                        5,
                        10,
                        15,
                        50
                    ]
                }"
            >
                <template v-slot:header.id>
                    {{ $capitalize($tc("model.automation_id_column_table", 1)) }}
                </template>

                <template v-slot:header.action_name>
                    {{ $capitalize($tc("model.automation_action_column_table", 1)) }}
                </template>

                <template v-slot:header.target>
                    {{ $capitalize($tc("model.automation_target_column_table", 1)) }}
                </template>

                <template v-slot:header.author_name>
                    {{ $capitalize($tc("model.automation_author_column_table", 1)) }}
                </template>

                <template v-slot:header.created_at>
                    {{ $capitalize($tc("model.automation_created_column_table", 1)) }}
                </template>

                <template v-slot:header.updated_at>
                    {{ $capitalize($tc("model.automation_change_column_table", 1)) }}
                </template>
                
                <template v-slot:header.status_id>
                    {{ $capitalize($tc("model.automation_status_column_table", 1)) }}
                </template>

                <template v-slot:item.id="{ item }">
                    <a @click="viewJob(item.id)">
                        {{ item.id }}
                    </a>
                </template>
                <template v-slot:item.action_name="{ item }">
                    <a @click="viewJob(item.id)">
                        {{ item.action_name }}
                    </a>
                </template>
                <template v-slot:item.status_id="{ item }">
                    <JobStatusChip :id="item.status_id" :name="item.status_name" :type="item.status_type" small />
                </template>
            </v-data-table>
        </v-card>

        <GetSingleLeadForm @submit="getItems()" ref="get_single_lead_form" />
        <JobViewer ref="viewer" />
    </v-container>
</template>

<script>
import { index, cancel, restore } from "@/services/lusitania/robots/jobs";

import JobStatusChip from "@/components/lusitania/JobStatusChip";
import GetSingleLeadForm from "@/components/lusitania/actions/GetSingleLeadForm";
import JobViewer from "@/components/lusitania/JobViewer"
import DataTableHelper from "@/tools/data_table";

export default {
    components: { JobStatusChip, GetSingleLeadForm, JobViewer },

    data() {
        return {
            tab: null,
            type: "open",
            loading: false,
            selected: [],
            filter: "",
            headers: [
                {
                    text: "Id",
                    value: "id",
                    width: 100
                },
                {
                    text: "Ação",
                    value: "action_name"
                },
                {
                    text: "Target",
                    value: "target"
                },
                {
                    text: "Autor",
                    value: "author_name"
                },
                {
                    text: "Criado em",
                    value: "created_at"
                },
                {
                    text: "Última alteração",
                    value: "updated_at"
                },
                 {
                    text: "Status",
                    value: "status_id",
                    width: 120
                },
            ],
            items: [],
            totalItems: 0,
            options: {
                itemsPerPage: 10,
                sortBy: ["id"],
                sortDesc: [true],
                page: 1,
            }
        }
    },

    watch: {
        options: {
            handler(value) {
                this.getItems();
            },
            deep: true,
        },
        tab: function(value) {
            if(value === 0) {
                this.type = "open";
            }

            if(value === 1) {
                this.type = "closed";
            }

            if(value === 2) {
                this.type = "all";
            }

            this.getItems(false, true);
        }
    },

    computed: {
        canView() {
            if(this.selected.length === 1) return true;

            return false;
        },
        canClose() {
            if(this.selected.length === 0) return false;

            return true;
        },
        canRestore() {
            if(this.selected.length === 0) return false;

            return true;
        }
    },

    methods: {
        handleClearFilters() {
            this.filter = "";
            this.getItems(false, true);
        },
        handleViewJob() {
            this.viewJob(this.selected[0].id);
        },
        viewJob(jobId) {
            this.$refs.viewer.init(jobId);
        },
        handleOpen() {
            this.$refs.get_single_lead_form.init();
        },
        async getItems(silent = false, reset = false) {
            if(silent == false) {
                this.items = [];
            }

            if(reset) {
                this.options.page = 1;
            }

            this.loading = true;

            const params = DataTableHelper.getParams(this.options, "");
            const response = await index({
                ...params,
                type: this.type,
                filter: this.filter
            });

            this.items = response.data;
            this.totalItems = response.total;
            this.loading = false;
        },
        async cancelItem(item) {
            const response = await cancel(item.id);

            return response;
        },
        async handleCancelItems() {
            if(!confirm(this.$capitalize(this.$tc("model.automation_cancel_all_message")))) 
                return;

            this.loading = true;
            const items = this.selected;

            for (let item of items) {
                try {
                    await this.cancelItem(item);
                } catch(error) {
                    let message = `Não foi possível cancelar o job ${item.id}`;

                    if(error.response.status === 400) {
                        message = error.response.data.message;
                    }

                    this.$store.commit('error', message);
                }
            }

            this.selected = [];

            this.getItems();
        },
        async restoreItem(item) {
            const response = await restore(item.id);

            return response;
        },
        async handleRestoreItems() {
            if(!confirm(this.$capitalize(this.$tc("model.automation_restore_all_message")))) 
                return;

            const items = this.selected;
            this.loading = true;

            for (let item of items) {
                try {
                    await this.restoreItem(item);
                } catch(error) {
                    let message = `Não foi possível restaurar o job ${item.id}`;

                    if(error.response.status === 400) {
                        message = error.response.data.message;
                    }

                    this.$store.commit('error', message);
                }
            }

            this.selected = [];

            this.getItems();
        },
    },

    created() {
        this.getItems();
    }
}
</script>