<template>
    <v-chip v-bind="$attrs" label outlined :color="color">
        {{ name }}
    </v-chip>
</template>

<script>
export default {
    props: {
        id: String,
        name: String,
        type: String
    },

    computed: {
        color: function() {
            if(this.type === "warning") {
                return "orange";
            }

            if(this.type === "error") {
                return "red";
            }

            if(this.type === "success") {
                return "success";
            }

            return "info";
        }
    }
}
</script>