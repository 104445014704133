var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600","fullscreen":_vm.$vuetify.breakpoint.xs},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_vm._v(" Dados Job "),_c('v-btn',{attrs:{"icon":"","disabled":_vm.loading},on:{"click":function($event){return _vm.getData(_vm.id)}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.handleClose}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-subtitle',[_vm._v(" ID: "+_vm._s(_vm.id)+" ")]),(_vm.job)?_c('v-card-text',[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Status ")]),_c('v-list-item-subtitle',[_c('JobStatusChip',{attrs:{"id":_vm.job.status.id,"name":_vm.job.status.name,"type":_vm.job.status.type,"x-small":""}})],1)],1)],1),(_vm.job.lead)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Lead ")]),_c('v-list-item-subtitle',[_c('v-chip',{attrs:{"small":"","label":"","to":{
                                    name: 'lead_detail_data',
                                    params: {
                                        lead_number: _vm.job.lead.identifier_code
                                    }
                                }}},[_vm._v(" "+_vm._s(_vm.job.lead.identifier_code)+" ")])],1)],1)],1):_vm._e(),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Autor ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.job.creator.name)+" ")])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Robô Responsável ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.job.robot ? _vm.job.robot.id : "Nenhum")+" ")])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Data de criação ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.job.created_at)+" ")])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Última modificação ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.job.updated_at)+" ")])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.job.action.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" AID: "+_vm._s(_vm.job.action.id)+" ")])],1)],1)],1),_c('v-timeline',{staticClass:"d-none d-sm-inline-block",attrs:{"dense":""}},_vm._l((_vm.job.logs),function(log){return _c('v-timeline-item',{key:log.id,attrs:{"fill-dot":"","small":"","color":_vm.getColor(log.type),"icon":log.is_a_sytem_action ? 'mdi-cog' : '',"title":log.is_a_sytem_action ? 'Ação automática' : 'Ação manual'}},[_c('v-row',{staticClass:"pt-1",attrs:{"dense":""}},[_c('v-col',[_c('strong',[(_vm.job.status_id == 'needs_user_input' && log.technical_details.status.id == 'needs_user_input')?_c('router-link',{staticClass:"link",attrs:{"to":{
                                        name: 'lead_new_import_robot',
                                        params: {
                                            job_id: _vm.job.id,
                                        }
                                    }}},[_vm._v(" "+_vm._s(log.message)+" ")]):_c('span',[_vm._v(" "+_vm._s(log.message)+" ")])],1),_c('div',{staticClass:"text-caption"},[_vm._v(" Responsável: "+_vm._s(log.author ? log.author.name : "Sistema")+" ")]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(log.created_at)+" ")]),_c('div',[_c('JobStatusChip',{attrs:{"id":log.technical_details.status.id,"name":log.technical_details.status.name,"type":log.technical_details.status.type,"x-small":""}}),_c('v-chip',{staticClass:"ml-1",attrs:{"x-small":"","outlined":""},on:{"click":function($event){return _vm.logDetails(log)}}},[_vm._v(" detalhes ")])],1)])],1)],1)}),1)],1):_vm._e(),(_vm.job)?_c('v-list',{staticClass:"d-sm-none"},[_c('v-subheader',[_vm._v("LOGS")]),_vm._l((_vm.job.logs),function(log){return _c('v-list-item',{key:log.id,attrs:{"fill-dot":"","small":"","color":_vm.getColor(log.type),"icon":log.is_a_sytem_action ? 'mdi-cog' : '',"title":log.is_a_sytem_action ? 'Ação automática' : 'Ação manual'}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap"},[(_vm.job.status_id == 'needs_user_input' && log.technical_details.status.id == 'needs_user_input')?_c('router-link',{staticClass:"link",attrs:{"to":{
                                name: 'lead_new_import_robot',
                                params: {
                                    job_id: _vm.job.id,
                                }
                            }}},[_vm._v(" "+_vm._s(log.message)+" ")]):_c('span',[_vm._v(" "+_vm._s(log.message)+" ")])],1),_c('v-list-item-subtitle',[_vm._v(" Responsável: "+_vm._s(log.author ? log.author.name : "Sistema")+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(log.created_at)+" ")]),_c('v-list-item-subtitle',[_c('JobStatusChip',{attrs:{"id":log.technical_details.status.id,"name":log.technical_details.status.name,"type":log.technical_details.status.type,"x-small":""}}),_c('v-chip',{staticClass:"ml-1",attrs:{"x-small":"","outlined":""},on:{"click":function($event){return _vm.logDetails(log)}}},[_vm._v(" detalhes ")])],1)],1)],1)})],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }