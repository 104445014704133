<template>
    <v-dialog v-model="dialog" max-width="500" :fullscreen="$vuetify.breakpoint.xs">
        <v-card :disabled="loading">
            <v-card-title>
                Buscar serviços
                <v-spacer></v-spacer>
                <v-btn @click="handleClose" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-subtitle>
                Preencha o formulário ou copie e cole os <b>nºs processo LUS</b> direto do Excel
            </v-card-subtitle>

            <v-card-text>
                <v-row dense>
                    <v-col>
                        <v-chip @click="pasteServices">
                            <v-icon small left>mdi-content-copy</v-icon> Colar códigos
                        </v-chip>
                        <v-chip @click="handleClearServices" class="ml-1">
                            <v-icon small left>mdi-backspace</v-icon> Limpar
                        </v-chip>
                    </v-col>
                </v-row>

                <v-row v-for="(service, index) in services" :key="index" dense>
                    <v-col cols="5" sm="6">
                        <v-text-field v-model="services[index].year" label="Ano" outlined dense single-line hide-details></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="services[index].number" label="Número" outlined dense single-line hide-details>
                            <template v-slot:append-outer>
                                <v-btn @click="deleteService(index)" icon small>
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn @click="handleNewService" text block color="primary">
                            + serviço
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions>
                <v-btn @click="handleSubmit" color="primary" block :loading="loading" :disabled="services.length === 0">
                    Criar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { storeGetSingleService } from "@/services/lusitania/robots/jobs";

export default {
    data() {
        return {
            dialog: false,
            tab: null,
            services: [
                {
                    year: "",
                    number: ""
                }
            ],
            loading: false,
            cliptext: ""
        };
    },

    methods: {
        pasteServices() {
            navigator.clipboard.readText().then(cliptext => {
                this.addServices(cliptext);
            })
        },
        handleClose() {
            this.dialog = false;
            this.$emit('close');
        },
        deleteService(index) {
            if(this.services.length === 1) {
                this.handleClearServices();
                return;
            }

            this.services.splice(index, 1);
        },
        init() {
            this.dialog = true;
            this.tab = 0;
            this.services = [
                {
                    year: "",
                    number: ""
                }
            ];
        },
        handleNewService() {
            this.services.push({
                    year: "",
                    number: ""
                })
        },
        handleClearServices() {
            this.services = [
                {
                    year: "",
                    number: ""
                }
            ];
        },
        async save() {
            const response = await storeGetSingleService({
                services: this.services
            });

            return response;
        },
        async handleSubmit() {
            this.loading = true;

            try {
                const response = await this.save();
                this.$emit('submit', response);
                this.dialog = false;
            } catch(error) {
                this.$storage.commit('error', 'Não foi possível criar jobs')
            } finally {
                this.loading = false;
            }
        },
        addServices(value) {
            const lines = value.split('\n');
            const services = [];
            
            lines.filter(line => !!line).forEach(line => {
                const rawService = line.includes(".") ? line.split('.')[1] : line;
                
                if(!rawService.includes("/")) return;

                const parts = rawService.split('/');
                if(parts[0] && parts[1])
                    services.push({
                        year: parts[0],
                        number: parts[1]
                    })
            });

            if(services.length === 0) {
                this.handleNewService();
            }

            this.services = [...services];
        }
    },

    computed: {
        raw: {
            get() {
                let text = "";
                this.services.forEach(service => {
                    if(service.year && service.number)
                        text += `${service.year}.${service.number}\n`
                })

                return text;
            },
            set(value) {
                if(!value) return;

                const lines = value.split('\n');
                const services = [];
                
                lines.filter(line => !!line).forEach(line => {
                    const rawService = line.includes(".") ? line.split('.')[1] : line;
                    
                    if(!rawService.includes("/")) return;

                    const parts = rawService.split('/');
                    if(parts[0] && parts[1])
                        services.push({
                            year: parts[0],
                            number: parts[1]
                        })
                });

                if(services.length === 0) {
                    this.handleNewService();
                }

                this.services = [...services];
            }
        }
    }
}
</script>